<template>
  <div class="edit-product-page" v-if="product">
    <BackTitle :confirm-click="!product.isDeleted" @click="goBack">{{
      product.isDeleted ? product.title : "Edit product"
    }}</BackTitle>
    <Loader v-if="isLoading" class="mx-auto" color="primary" size="m" />
    <Guard v-else permission="products.edit" v-slot="{ isAvailable }">
      <ProductForm
        :value="product"
        :is-submitting="isSubmitting"
        :is-disabled="product.isDeleted || !isAvailable"
        @submit="save"
      />
    </Guard>
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import ProductForm from "@/components/products/ProductForm";
import { mapActions, mapState } from "vuex";
import Guard from "@/components/common/Guard";

export default {
  name: "EditProductPage",
  components: { Guard, ProductForm, BackTitle },
  props: {},
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
    };
  },
  computed: {
    ...mapState({
      product: (state) => state.products.editedItem,
    }),
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchProductById(this.$route.params.id);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      fetchProductById: "products/fetchProductById",
      updateProduct: "products/updateProduct",
      saveAffectedBookings: "bookings/saveAffectedBookings",
    }),
    async save({ id, ...data }) {
      try {
        this.isSubmitting = true;
        await this.updateProduct({
          id,
          data,
        });
        this.goBack();
      } finally {
        this.isSubmitting = false;
      }
    },
    goBack() {
      this.$router.push({
        name: "Products",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-product-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
}
</style>
